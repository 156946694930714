<template>
    <div class="flex flex-wrap gap-4 items-end">
        <div class="grow max-w-sm">

            <select class="bg-eibblue20 rounded-md border-none w-full text-sm" v-model="value"
                :data-testid="test ? test : 'select'">
                <option value="" disabled selected="selected" v-if="placeholder" class="text-black">
                    {{ placeholder }}
                </option>
                <option class="bg-white border-none" v-for="(option, i) in options" :key="`option${i}`"
                    :value="getValue(option)" v-html="getText(option)"></option>
            </select>

        </div>
        <div class="grow max-w-xs">
            <div class="flex gap-x-4">
                <div class="self-end">
                    <div class="flex inline-flex gap-2">
                        <!-- the secondaryOption can be used to select the unit of the numeric value -->
                        <select v-if="secondaryOption" class="bg-eibblue20 rounded-md border-none w-full text-sm"
                            v-model="secondaryOption">
                            <option value="" disabled selected="selected" v-if="placeholder" class="text-black">
                                {{ placeholder }}
                            </option>
                            <option class="bg-white border-none" v-for="(option, i) in secondaryOptions"
                                :key="`soption${i}`" :value="option">{{ option }}</option>
                        </select>
                        <!-- the numeric input -->
                        <cleave :raw="false" :options="cleaveoptions" v-model="numeric_value" :min="min" :max="max"
                            :data-testid="test" :disabled="false" :styled="styled" :class="[
                                'h-9 shadow-sm block w-full sm:text-sm border-none rounded-md px-3 focus:border-blue-700 focus:ring-blue-700',
                                styled === 'meta_field' ? 'bg-gray-100' : 'bg-eibblue20',
                            ]" inputmode="decimal" />
                    </div>
                </div>

                <div class="flex">
                    <span v-html="suffix"></span>
                </div>
                <div v-if="showTrash" class="flex justify-between items-center float-right cursor-pointer">
                    <BaseButton @click="remove" size="sm" :primary="false" :secondary="true">
                        <XMarkIcon class="h-4 w-4 float-left" />
                    </BaseButton>
                </div>
            </div>
        </div>
        <ValidationMessage :validation="validation" />
    </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue'
import Cleave from 'vue-cleave-component'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

export default {
    components: { Cleave, XMarkIcon, BaseButton, ValidationMessage },
    name: 'MultiLineItem',
    props: {
        showTrash: Boolean,
        index: Number,
        options: [Array],
        modelValue: [Object],
        test: String,
        placeholder: String,
        styled: {
            type: String,
            required: false,
        },
        suffix: String,
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
        },
        validation: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            cleaveoptions: {
                numeral: true,
                numeralDecimalMark: '.',
                delimiter: ' ',
            },
        }
    },
    computed: {
        secondaryOptions() {
            if (this.modelValue && this.modelValue.value) {
                const item = this.options.find((option) => option.value === this.modelValue.value)
                return item.additional_options
            }
            return []
        },
        secondaryOption: {
            get() {
                if (this.modelValue && this.modelValue.additional_value) {
                    return this.modelValue.additional_value
                }
                if (this.modelValue && this.modelValue.value) {
                    this.modelValue.additional_value = this.secondaryOptions[0]
                    return this.secondaryOptions[0]
                }
                return ""
            },
            set(val) {
                this.$emit(
                    'update:modelValue',
                    Object.assign(
                        {},
                        {
                            value: {
                                value: this.modelValue.value,
                                numeric_value: this.modelValue.numeric_value,
                                additional_value: val,
                            },
                            index: this.index,
                        }
                    )
                )
            },
        },
        value: {
            get() {
                if (this.modelValue) {
                    return this.modelValue.value
                }
                return {}
            },
            set(val) {
                // console.log(val)
                this.$emit(
                    'update:modelValue',
                    Object.assign(
                        {},
                        {
                            value: {
                                value: val,
                                numeric_value: this.modelValue.numeric_value,
                                additional_value: this.modelValue.additional_value,
                            },
                            index: this.index,
                        }
                    )
                )
            },
        },
        numeric_value: {
            get() {
                if (this.modelValue) {
                    return this.modelValue.numeric_value
                }
                return {}
            },
            set(val) {
                // console.log(val)
                this.$emit(
                    'update:modelValue',
                    Object.assign(
                        {},
                        {
                            value: {
                                value: this.modelValue.value,
                                numeric_value: parseInt(val.replace(/ /g, ''), 10),
                                additional_value: this.modelValue.additional_value,
                            },
                            index: this.index,
                        }
                    )
                )
            },
        },
    },
    methods: {
        getValue(item) {
            if (typeof item === 'object') {
                return item.value
            }
            return item
        },
        getText(item) {
            if (typeof item === 'object') {
                return item.text
            }
            return item
        },
        remove() {
            this.$emit('remove', this.index)
        },
    },
}
</script>
